import React from "react"
import { AppStoreBadge } from "../components/app-store-badge"
import { CofuAppHomeScreenshot } from "../components/couf-app-home-screenshot"
import { CofuAppIcon } from "../components/couf-app-icon"
import { Footer } from "../components/footer"
import { SEO } from "../components/seo"
import styles from "./index.module.css"

const Background = () => (
  <div className={styles.backgroundContainer}>
    <div className={styles.backgroundTop}></div>
    <div className={styles.backgroundBottom}></div>
  </div>
)

const IndexPage = () => (
  <>
    <SEO title="Plan meals together" />

    <main className={styles.pageContainer}>
      <Background></Background>

      <div className={styles.contentContainer}>
        <CofuAppIcon />

        <h1 className={styles.title}>Plan meals together</h1>

        <div className={styles.appStoreBadgeContainer}>
          <a href="https://apps.apple.com/us/app/cofu/id1534730784">
            <AppStoreBadge></AppStoreBadge>
          </a>
        </div>

        <div className={styles.screenshotContainer}>
          <div className={styles.screenshot}>
            <CofuAppHomeScreenshot></CofuAppHomeScreenshot>
          </div>
        </div>
      </div>

      <Footer dark></Footer>
    </main>
  </>
)

export default IndexPage
