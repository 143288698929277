import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const AppStoreBadge: React.FC = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "App-Store-Badge.svg" }) {
        publicURL
      }
    }
  `)
  return <img src={query.file.publicURL} alt="App store badge" />
}
