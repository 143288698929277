import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

export const CofuAppHomeScreenshot: React.FC = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Cofu-App-Home-Screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return <Img fluid={query.file.childImageSharp.fluid} alt="App screenshot" />
}
